@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/helpers/DataTable/Templates/ColumnTemplates/General.module" as General;

.surveillance-results {
  &__table {
    @include Tools.datatable-new-item();

    .p-datatable-wrapper {
      flex-direction: column;
    }
    .p-virtualscroller {
      flex: 1;
    }

    // center selection checkbox within column
    .p-selection-column {
      flex: 1;
      padding: 0;
      justify-content: center;
      justify-items: center;

      .p-column-header-content {
        flex: 0;
      }
    }

    tr {
      display: grid;
      grid-template-columns: Tools.asRem(48px) Tools.asRem(160px) Tools.asRem(160px) 1fr Tools.asRem(110px) Tools.asRem(180px) 1fr;
      align-content: center;
      cursor: pointer;

      &.p-selectable-row:not(.p-highlight):not(:hover)[tabindex="0"] {
        outline: none;
      }

      &:not(.p-rowgroup-header):not(.p-selected-row).p-highlight,
      &:not(.p-rowgroup-header):not(.p-selected-row).p-highlight .p-frozen-column {
        background-color: unset;

        td span.lower {
          color: Colors.$text-secondary;
        }
      }

      th {
        .p-column-header-content {
          flex: 1;

          .p-column-title:empty {
            display: none;
          }
        }
        .p-column-title {
          width: 100%;
        }
      }
    }

    &-extended {
      tr {
        grid-template-columns: Tools.asRem(48px) Tools.asRem(160px) Tools.asRem(45px) Tools.asRem(160px) 1fr Tools.asRem(110px) Tools.asRem(180px) 1fr;
      }
    }
    &-escalated {
      tr {
        grid-template-columns: Tools.asRem(48px) Tools.asRem(160px) Tools.asRem(168px) 1fr Tools.asRem(100px) Tools.asRem(160px) 1fr Tools.asRem(175px);
      }
    }
    &-compressed {
      & thead tr {
        grid-template-columns: Tools.asRem(48px) 1fr;

        th:nth-child(2) {
          grid-column: 2 / 8;
        }
        /* hide all headers except selection and first data columns */
        th:not(:first-child):not(:nth-child(2)) {
          display: none;
        }
      }

      & tbody tr {
        &:not(.p-datatable-emptymessage) {
          grid-template-columns: Tools.asRem(48px) repeat(10, 1fr) Tools.asRem(110px);
          grid-template-rows: repeat(3, auto);

          & td {
            display: flex;

            &:not(.no--padding):nth-child(4) {
              padding-top: Tools.asRem(2px);
              padding-bottom: Tools.asRem(4px);
            }

            /* selection checkbox */
            &:first-child.p-frozen-column {
              border-right: none;
              box-shadow: none;
            }
            /* 3 - status cell */
            &:nth-child(3) {
              justify-content: flex-end;

              > * {
                margin: 0;
              }
            }
            /* 4,8 - first cells in a row */
            &:nth-child(4),
            &:nth-child(8) {
              &:not(.no--padding) { padding-left: Tools.asRem(Spacing.$gutter-medium); }
              &.no--padding { padding-left: Tools.asRem(20px); }
            }
          }

          & td:first-child { grid-area: 1 / 1 / 1 / 1; }
          & td:nth-child(2) { grid-area: 1 / 2 / 1 / 12; }
          & td:nth-child(3) { grid-area: 1 / 12 / 1 / 12; }
          & td:nth-child(4) { grid-area: 2 / 1 / 2 / 5; }
          & td:nth-child(5) { grid-area: 2 / 5 / 2 / 9; }
          & td:nth-child(6) { grid-area: 2 / 12 / 2 / 12; }
          & td:nth-child(7) { grid-area: 2 / 9 / 2 / 12; }
          & td:nth-child(8) { grid-area: 3 / 1 / 3 / 13; }
        }
      }

      &.surveillance-results__table-reviewed {
        & tbody tr {
          &:not(.p-datatable-emptymessage) {

            & td {
              &:not(.no--padding):nth-child(3) {
                padding-top: Tools.asRem(2px);
                padding-bottom: Tools.asRem(4px);
              }
              /* 3 - user cell */
              &:nth-child(3) {
                justify-content: inherit;
  
                > * {
                  margin: 0;
                }
              }
              /* 4,8 - first cells in a row */
              &:nth-child(3),
              &:nth-child(7) {
                &:not(.no--padding) { padding-left: Tools.asRem(Spacing.$gutter-medium); }
                &.no--padding { padding-left: Tools.asRem(20px); }
              }
            }

            & td:first-child { grid-area: 1 / 1 / 1 / 1; }
            & td:nth-child(2) { grid-area: 1 / 2 / 1 / 13; }
            & td:nth-child(3) { grid-area: 2 / 1 / 2 / 5; }
            & td:nth-child(4) { grid-area: 2 / 5 / 2 / 9; }
            & td:nth-child(5) { grid-area: 2 / 12 / 2 / 12; }
            & td:nth-child(6) { grid-area: 2 / 9 / 2 / 12; }
            & td:nth-child(7) { grid-area: 3 / 1 / 3 / 13; }
          }
        }
      }

      .multi-line-cell {
        > * {
          gap: 0;
        }
        span {
          font-size: Tools.asRem(13px);
          font-weight: 400;
        }
        span.multi-line-cell-header {
          color: Colors.$text-secondary;
          font-size: Tools.asRem(8px);
          font-weight: 700;
          text-transform: uppercase;
        }

        &__child-column-direction > * {
          flex-direction: column;
        }
      }

      .brakeLines {
        white-space: break-spaces;
      }
    }

    th.intermediate .p-checkbox .p-checkbox-box {
      background-color: Colors.$ob-brand-orange;

      // hide OOB checkbox mark to display horizontal line instead
      svg {
        display: none;
      }

      &:after {
        content: "\002015"; // horizontal line for intermediate state
      }
    }

    .p-datatable-tbody {
      & > tr:not(.p-rowgroup-header) {
        & > .upper {
          font-family: OpenSans, sans-serif;
          font-size: Tools.asRem(13px);
          font-weight: 700;
          line-height: Tools.asRem(18px);
          color: Colors.$text-primary;
        }

        & > td .lower {
          font-family: OpenSans, sans-serif;
          font-size: Tools.asRem(11px);
          font-weight: 400;
          line-height: Tools.asRem(15px);
          color: Colors.$text-secondary;
        }
      }

      .p-selection-column {
        min-height: Tools.asRem(34px);
        color: transparent; // When checkbox is hidden with showSelectionElement - primereact writes "false" inside cell
      }

      tr:not(.p-rowgroup-header).p-selected-row .p-selection-column,
      tr:not(.p-rowgroup-header).p-highlight .p-selection-column {
        color: transparent;
      }

      .p-datatable-emptymessage {
        cursor: default;
        color: Colors.$text-primary;

        strong {
          color: Colors.$text-secondary;
          font-family: Panton;
          font-size: Tools.asRem(18px);
        }
      }

      .single-line-sibling {
        display: flex;
        flex-direction: row;

        i {
          margin-right: Tools.asRem(Spacing.$gutter-small);
          margin-left: -1 * Tools.asRem(Spacing.$gutter-micro);
        }
      }
    }

    .p-chip,
    .p-datatable-tbody > tr.p-highlight td > .p-chip,
    .p-datatable-tbody > tr:not(.p-rowgroup-header).p-selected-row td > .p-chip {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      padding: 0 Tools.asRem(Spacing.$gutter-small);
      height: Tools.asRem(24px);

      &.chip-voice {
        background: Colors.$indigo-20;
        border: 1px solid Colors.$indigo;

        .p-chip-text {
          color: Colors.$indigo;
        }
      }

      &.chip-cellular,
      &.chip-sms {
        background: Colors.$tomato-20;
        border: 1px solid Colors.$tomato;

        .p-chip-text {
          color: Colors.$tomato;
        }
      }

      &.chip-whatsappaudio,
      &.chip-whatsappmessage {
        background: Colors.$grass-20;
        border: 1px solid Colors.$grass;

        .p-chip-text {
          color: Colors.$grass;
        }
      }

      span {
        padding: 0;
        margin: 0;
        font-family: OpenSans, sans-serif;
        font-weight: 700;
        font-size: Tools.asRem(13px);
        line-height: Tools.asRem(18px);
      }
    }

    & .spacing {
      &--small {
        gap: Spacing.$gutter-small;
      }
    }

    td,
    td > div,
    td > div > span {
      display: block;
      align-content: center;
      @extend .truncated;
    }

    .not-available__container {
      font-style: italic;
      color: Colors.$text-secondary;
    }

    .content-cell,
    .content-cell > div {
      display: flex;
    }

    &-header {
      position: relative;
      align-items: center;

      &-compressed:has(> .p-column-header-content) {
        padding-right: Tools.asRem(Spacing.$gutter-large);
      }
    }
  }
}

.sky-badge {
  display: flex;
  padding: Tools.asRem(6px) Tools.asRem(5px);
  border-radius: Tools.asRem(2px);
  border: Tools.asRem(1px) solid Colors.$sky-80;
  font-family: OpenSans, sans-serif;
  font-size: Tools.asRem(13px);
  font-weight: 700;
  line-height: Tools.asRem(Spacing.$gutter-large);
  background-color: Colors.$panel-background;
  color: Colors.$sky-80;
  width: min-content;
  min-width: Tools.asRem(20px);
  max-width: Tools.asRem(28px);
  justify-content: center;
  margin: 0 auto Tools.asRem(1px) auto;

  .p-datatable-tbody > tr:not(.p-rowgroup-header).p-selected-row td & {
    color: Colors.$sky-80;
  }

  i.icon--small::before {
    width: Tools.asRem(Spacing.$gutter-large);
  }
}
